import React from 'react'
import {Link} from "react-router-dom";
import data from '../../data/locData';


function Banner() {
    const banner = data.location.banner
    const listItemStyle = data.settings

    return (

        <>
            <div id="orderonline" className="heroBanner newDesignBanner displaySection">
                <div className="container">
                    <div className="rowFlex">
                        <div className="leftText">
                            <h1 style={{color: listItemStyle.seconderyColor}}>{banner.title}</h1>
                            <p style={{color: listItemStyle.seconderyColor}}>{banner.description}</p>
                            <a
                                className={banner.button_link === "" ? "hide" : ""}
                                style={{
                                    background: listItemStyle.seconderyColor, color: listItemStyle.buttonColor,
                                    boxShadow: listItemStyle.boxShadow
                                }}
                                href={banner.button_link}>{banner.button_name}</a>
                        </div>
                        <div className="leftImg">
                            <img src={banner.thumbnail} alt="thumbnail"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner