import React from 'react'
import {Link} from 'react-router-dom'
import data from '../../data/locData';

const Locations = () => {
    const resrData = data.location;
    const listItemStyle = data.settings;

    const formatPhoneNumber = (phoneNumberString) => {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    const mapUrl = (address) => {
        // TODO: Change API Key
        const apiKey = "AIzaSyBeTyP30NyY5nYm0pIdBWZhoAdW4ctBKvk";
        return "https://www.google.com/maps/embed/v1/place?key="
            + apiKey
            + "&q=" + address.replace(/ /g, '+');
    }

    return (
        <>
            <div id="contactus" className="contactSection displaySection">
                <div className="container">
                    <div className="rowFlex">
                        <h2 style={{color: listItemStyle.primaryColor}}>Locations</h2>
                    </div>
                    <div className="rowFlex location">
                        <div className="map">
                            <iframe title={`ContactUs`}
                                    src={mapUrl(resrData.contact_us.address)}
                                    frameBorder="0" style={{width: '650px', height: '400px'}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                            />
                        </div>
                        <div className="address">
                            <h3 style={{
                                color: listItemStyle.primaryColor,
                                fontSize: '2em',
                            }}>Norfolk, VA</h3>
                            <div className='contactInfo'>
                                <div className="calling">
                                    <div className="iconCallMobile">
                                        <div style={{
                                            background: listItemStyle.seconderyColor,
                                            color: listItemStyle.primaryColor,
                                            border: '1px solid ' + listItemStyle.primaryColor,
                                            fontSize: '1.5em'
                                        }} className="iconCall">
                                            <i className="fa-solid fa-phone"></i>
                                        </div>
                                    </div>
                                    <div className="numberCall">
                                        <h4 style={{color: 'black'}}>Call Us</h4>
                                        <Link style={{color: 'black'}} to="#">{formatPhoneNumber(resrData.contact_us.phone)}</Link>
                                    </div>
                                </div>
                                <div className="calling last-cal">
                                    <div className="iconCallMobile">
                                        <div style={{
                                            background: listItemStyle.seconderyColor,
                                            color: listItemStyle.primaryColor,
                                            border: '1px solid ' + listItemStyle.primaryColor,
                                            fontSize: '1.5em'
                                        }} className="iconCall">
                                            <i className="fa-regular fa-clock"></i>
                                        </div>
                                    </div>
                                    <div className="numberCall businessHours">
                                        <h4 style={{color: 'black'}}>Business Hours:</h4>
                                        <p style={{color: 'black'}}>{resrData.contact_us.business_hours}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rowFlex location">
                        <div className="map">
                            <iframe title={`ContactUs`}
                                    src={mapUrl("1590 Pleasant Hill Road, Duluth, GA, 30096")}
                                    frameBorder="0" style={{width: '650px', height: '400px'}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                            />
                        </div>
                        <div className="address">
                            <h3 style={{
                                color: listItemStyle.primaryColor,
                                fontSize: '2em',
                            }}>Duluth, GA</h3>
                            <div className='contactInfo'>
                            <div className="calling">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.seconderyColor,
                                        color: listItemStyle.primaryColor,
                                        border: '1px solid ' + listItemStyle.primaryColor,
                                        fontSize: '1.5em'
                                    }} className="iconCall">
                                        <i className="fa-solid fa-phone"></i>
                                    </div>
                                </div>
                                <div className="numberCall">
                                    <h4 style={{color: 'black'}}>Call Us</h4>
                                    <Link style={{color: 'black'}} to="#">{formatPhoneNumber("4703958147")}</Link>
                                </div>
                            </div>
                            <div className="calling last-cal">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.seconderyColor,
                                        color: listItemStyle.primaryColor,
                                        border: '1px solid ' + listItemStyle.primaryColor,
                                        fontSize: '1.5em'
                                    }} className="iconCall">
                                        <i className="fa-regular fa-clock"></i>
                                    </div>
                                </div>
                                <div className="numberCall businessHours">
                                    <h4 style={{color: 'black'}}>Business Hours:</h4>
                                    <p style={{color: 'black'}}>Mon-Fri: 4pm-1am <br />
                                    Sat-Sun: 12pm-2am</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="rowFlex location">
                        <div className="map">
                            <iframe title={`ContactUs`}
                                    src={mapUrl("4958 Bayou Blvd Pensacola, FL, 32503")}
                                    frameBorder="0" style={{width: '650px', height: '400px'}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                            />
                        </div>
                        <div className="address">
                            <h3 style={{
                                color: listItemStyle.primaryColor,
                                fontSize: '2em',
                            }}>Pensacola, FL</h3>
                            <div className='contactInfo'>
                            <div className="calling">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.seconderyColor,
                                        color: listItemStyle.primaryColor,
                                        border: '1px solid ' + listItemStyle.primaryColor,
                                        fontSize: '1.5em'
                                    }} className="iconCall">
                                        <i className="fa-solid fa-phone"></i>
                                    </div>
                                </div>
                                <div className="numberCall">
                                    <h4 style={{color: 'black'}}>Call Us</h4>
                                    <Link style={{color: 'black'}} to="#">{formatPhoneNumber("8504662154")}</Link>
                                </div>
                            </div>
                            <div className="calling last-cal">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.seconderyColor,
                                        color: listItemStyle.primaryColor,
                                        border: '1px solid ' + listItemStyle.primaryColor,
                                        fontSize: '1.5em'
                                    }} className="iconCall">
                                        <i className="fa-regular fa-clock"></i>
                                    </div>
                                </div>
                                <div className="numberCall businessHours">
                                    <h4 style={{color: 'black'}}>Business Hours:</h4>
                                    <p style={{color: 'black'}}>Mon-Thurs: 12pm-10:30pm <br />
                                    Fri: 12pm-11:30pm<br />
                                    Sat: 11am-11:30pm<br />
                                    Sun: 11am-10:30pm</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Locations;