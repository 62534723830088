import React from 'react'

const PageNotFound = () => {
  return (
    <>
   <div className='errorPage'>
    <div>
    <h1>Whoops!</h1>
<p>Something went wrong</p>
    </div>

</div>
    </>
  )
}

export default PageNotFound