import React from 'react'
import {Link} from "react-router-dom";
import data from '../../data/locData';

function Footer() {
    const locData = data.location
    const social_media = data.location.social_media
    const listItemStyle = data.settings
    return (
        <>
            <footer className="displaySection">
                <div className="container">
                    <div className="rowFlex">
                        <div className="footerBrand fooColumn">
                            <Link to="#">
                                <img src={locData.logo_footer}
                                     alt="Logo"
                                     width="50"
                                     className={locData.logo_footer === "" ? "hide" : ""}/>
                                <h2 style={{color: listItemStyle.primaryColor}}>
                                    {locData.logo_footer === "" ? locData.name : ""}
                                </h2>
                            </Link>
                        </div>
                        <div className="socialLink fooColumn">
                            <span>{social_media.length === 0 ? "" : "Connect with us"}</span>
                            <>
                                {
                                    social_media.map((item, i) => {
                                        return (
                                            <a key={i} href={item.link} rel="noreferrer" target="_blank"><i className={"fa-brands fa-"+item.icon}></i></a>
                                        )
                                    })
                                }
                            </>
                        </div>
                        <div className="copyWrite fooColumn">
                            <p>Powered by
                                <a href="https://www.kloudeats.com" style={{color: listItemStyle.primaryColor}}> Kloudeats
                                </a>. Copyright © 2022 <Link style={{color: listItemStyle.primaryColor}} to="">{locData.name}
                                </Link>. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer