import React from 'react'
import Banner from './layouts/Banner'
import Header from './components/Header'
import Footer from './components/Footer'
import data from '../data/locData';
import SliderGallery from './layouts/SliderGallery';
import Reviews from './layouts/Reviews';
import Locations from './layouts/Locations';
import General from './layouts/General';


function Template1() {
    const banner = data.location.banner

    return (
        <>
            <div className='header-overly-banner' style={{
                backgroundImage: `url(${banner.image})`,
            }}>
                <Header/>
                <Banner/>
            </div>

            <div className="mainWrapper">
                <SliderGallery/>
                <General/>
                <Reviews/>
                <Locations/>
            </div>
            <Footer/>
        </>
    )
}

export default Template1