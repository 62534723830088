import React, { useState} from "react";
// import { NavLink } from "react-router-dom";
import styled from "styled-components";
// import Nav from "./Nav";
import { Link } from "react-scroll";
import data from "../../data/locData";

const COLORS = {
  primaryDark: "#2b2730",
  primaryLight: "#B6EDC8",
};

const banner = data.location.banner

const MenuLabel = styled.label`
  background-color: ${COLORS.primaryLight};
  position: fixed;
  top: 6rem;
  right: 6rem;
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
  text-align: center;
`;

const NavBackground = styled.div`
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background: var(--primaryDark) !important;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  z-index: 600;
  transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
  transition: transform 0.8s;
`;

const Icon = styled.span`
  position: relative;
  background-color: ${(props) => (props.clicked ? "transparent" : "#fff")};
  width: 2rem;
  height: 2px;
  display: inline-block;
  margin-top: 3.5rem;
  transition: all 0.3s;

  &::before,
  &::after {
    content: "";
    background-color: black;
    width: 2rem;
    height: 2px;
    display: inline-block;

    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  &::before {
    top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "0" : "0.8rem")};

    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }

 
`;

const Navigation = styled.nav`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 600;
  width: ${(props) => (props.clicked ? "100%" : "0")};
  opacity: ${(props) => (props.clicked ? "1" : "0")};
  overflow: scroll;

  transition: width 0.8s, opacity 0.8s;
`;



function HamburgerMenu() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  // const [click, setClick] = useState(false);

  const [navbar, setNavbar ] = useState(false);

  // const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);




  const changeBackground = () => {
    if(window.scrollY >= 80) {
        setNavbar(true);
    }
    else {
        setNavbar(false);
    }
};

window.addEventListener('scroll', changeBackground);





  return (
    <>
      <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
        <Icon clicked={click}>&nbsp;</Icon>
      </MenuLabel>
      <NavBackground clicked={click}>&nbsp;</NavBackground>

      <Navigation  clicked={click}>
      <nav className={navbar ? 'navbar active' : 'navbar'}>
        <div className="navbar-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
              <Link 
              activeClass="active"
              to="orderonline"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
               className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className={banner.button_link === "" ? "hide" : "nav-item"}>
              <Link 
              activeClass="active"
              to="orderonline"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
               className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                {banner.button_name}
              </Link>
            </li>
            <li className="nav-item">
              <Link activeClass="active"
                    to="gallery"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
              
                className={navbar ? 'nav-links active' : 'nav-links'}
                onClick={closeMobileMenu}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="generalSection"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={navbar ? 'nav-links active' : 'nav-links'}
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="reviews"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={navbar ? 'nav-links active' : 'nav-links'}
                onClick={closeMobileMenu}
              >
                Reviews
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="contactus"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={navbar ? 'nav-links active' : 'nav-links'}
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>

       
          </ul>
        
        </div>
      </nav>
      </Navigation>
    </>
  );
}

export default HamburgerMenu;
