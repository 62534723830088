const data = {
    "settings": {
        "template_id": 2,
        "primaryColor": "#C50000",
        "buttonColor": "#FF3434",
        "buttonBg": "#FFDCDC",
        "darkColor": "#000000",
        "seconderyColor": "#ffffff",
        "boxShadow": "0px 30px 80px 5px rgba(209, 43, 40, 0.3)",
        "bggradient": "linear-gradient(180deg, rgba(209, 43, 40, 0.09) 0%, rgba(255, 255, 255, 0.11) 100%)",
        "contactAddress": "linear-gradient(98.63deg, #FFF8F8 0%, rgba(255, 255, 255, 0) 100%)",
    },
    "location": {
        "id": 167,
        "name": "HQ Korean BBQ & Shabu Shabu",
        "category": "Restaurant",
        "url": "http://127.0.0.1:5500/index.html",
        "logo": "./img/v1/logo.png",
        "logo_footer": "./img/v1/logo.png",
        "banner": {
            "title": "HQ-Korean BBQ & Shabu Shabu",
            "description": "a unique, hands-on all-you-can-eat dining experience that merges traditional Japanese Shabu Shabu with Korean BBQ flavors",
            "image": "./img/v1/hero_bg.webp",
            "thumbnail": "./img/v1/hero_image.webp",
            "button_name": "",
            "button_link": ""
        },
        "gallery": [
            {
                "original": "./img/v1/gallery/1.webp",
                "thumbnail": "/img/v1/gallery/1.webp"
            },
            {
                "original": "./img/v1/gallery/2.webp",
                "thumbnail": "/img/v1/gallery/2.webp"
            }, {
                "original": "./img/v1/gallery/3.webp",
                "thumbnail": "/img/v1/gallery/3.webp"
            }, {
                "original": "./img/v1/gallery/3.webp",
                "thumbnail": "/img/v1/gallery/3.webp"
            }, {
                "original": "./img/v1/gallery/4.webp",
                "thumbnail": "/img/v1/gallery/4.webp"
            }, {
                "original": "./img/v1/gallery/5.webp",
                "thumbnail": "/img/v1/gallery/5.webp"
            }, {
                "original": "./img/v1/gallery/6.webp",
                "thumbnail": "/img/v1/gallery/6.webp"
            }, {
                "original": "./img/v1/gallery/7.webp",
                "thumbnail": "/img/v1/gallery/7.webp"
            }, {
                "original": "./img/v1/gallery/8.webp",
                "thumbnail": "/img/v1/gallery/8.webp"
            }, {
                "original": "./img/v1/gallery/9.webp",
                "thumbnail": "/img/v1/gallery/9.webp"
            }, {
                "original": "./img/v1/gallery/10.webp",
                "thumbnail": "/img/v1/gallery/10.webp"
            }, {
                "original": "./img/v1/gallery/12.webp",
                "thumbnail": "/img/v1/gallery/12.webp"
            }, {
                "original": "./img/v1/gallery/13.webp",
                "thumbnail": "/img/v1/gallery/13.webp"
            }, {
                "original": "./img/v1/gallery/14.webp",
                "thumbnail": "/img/v1/gallery/14.webp"
            }, {
                "original": "./img/v1/gallery/15.webp",
                "thumbnail": "/img/v1/gallery/15.webp"
            }, {
                "original": "./img/v1/gallery/16.webp",
                "thumbnail": "/img/v1/gallery/16.webp"
            }, {
                "original": "./img/v1/gallery/17.webp",
                "thumbnail": "/img/v1/gallery/17.webp"
            }, {
                "original": "./img/v1/gallery/18.webp",
                "thumbnail": "/img/v1/gallery/18.webp"
            }, {
                "original": "./img/v1/gallery/19.webp",
                "thumbnail": "/img/v1/gallery/19.webp"
            },
        ],
        "about": [
            {
                "img": "./img/v1/about1.webp",
                "title": "It’s more than just a meal",
                "description": "HQ-Korean BBQ & Shabu Shabu is for both the food adventurers and the social eaters. It’s about tasting the global spices and seasonings all while feeling a sense of community. It’s Shabu Shabu and Korean BBQ modernized with a full bar and nightlife atmosphere. But in the true spirit of those four friends, HQ-Korean BBQ & Shabu Shabu is mostly about gathering together for lively conversation over a feast of good food.",
            },
            {
                "img": "./img/v1/store.webp",
                "title": "Our Restaurant",
                "description": "HQ-Korean BBQ & Shabu Shabu is a unique, hands-on all-you-can-eat dining experience that merges traditional Japanese Shabu Shabu with Korean BBQ flavors – but modernized with a full bar and a nightlife-like atmosphere.",
            }
        ],
        "reviews": {
            "image": "./img/v1/review.webp",
            "contents": [
                {
                    "review": "What a wonderful ambiance and unique concept! They offer an incredibly diverse range of proteins, vegetables, and noodles for your personalized Japanese Shabu Shabu or grill. The service, led by Leo, was top-notch and the entire floor staff was superb! Make sure you save a little space for their delectable yet simple desserts. The drink menu was also impressive!",
                    "reviewer": "",
                },
                {
                    "review": "Wow, this spot is absolutely fantastic! We chose to focus on their Japanese Shabu Shabu, fully embracing the all-you-can-eat experience. Their beverage selection was expansive too, encompassing everything from sake and wine to beer and cocktails. Plus, the service was exceptional - our waiter made sure to consistently refill the water in our Shabu Shabu pots and cups.",
                    "reviewer": "",
                },
                {
                    "review": "The cuisine was exceptional and the service was top-tier! We thoroughly relished our visit and we're definitely planning to return!",
                    "reviewer": "",
                },
                {
                    "review": "Absolutely delicious with a wealth of choices! I adore the simplicity of selecting exactly what I want. The prices are quite fair for the high-quality food served. This has quickly become one of my go-to dining spots!",
                    "reviewer": "",
                },
                {
                    "review": "I absolutely adore this restaurant! They truly outdid themselves with the Korean Kimchi soup base! When I lived nearby, I was a near-daily visitor. My sincere thanks to them for always making my day brighter!",
                    "reviewer": "",
                }
            ]
        },
        "social_media": [
            {
                icon: "facebook",
                link: "https://www.facebook.com/hqbbqhotpot"
            },
            {
                icon: "instagram",
                link: "https://instagram.com/hqbbqhotpot_official"
            },
        ],
        "contact_us": {
            "phone": 7579370643,
            "address": "1020 N Military Hwy, Norfolk, VA 23502",
            "background": "./img/v1/contactus-banner.webp",
            "business_hours": "Sun - Thu: 12:00 PM To 10:30 PM\nFri - Sat: 12:00 PM To 11:30 PM"
        },
    }
}
export default data
