import React, {useEffect} from 'react'
import {Routes, Route} from "react-router-dom"
import data from './data/locData';
import './assets/css/style.css'
import Main from './main/Main';
import PageNotFound from './main/PageNotFound';

function App() {
    const locationName = data.location.name
    useEffect(() => {
        document.title = locationName;
    }, []);
    return (
        <>
            <Routes>
                <Route index element={<Main/>}/>
                <Route path='*' element={<PageNotFound/>}/>
            </Routes>
        </>
    );
}

export default App;
